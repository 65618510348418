import React from "react";
import { StaticQuery, graphql } from "gatsby";
import Helmet from "react-helmet";

const HELMET_QUERY = graphql`
  query HelmetQuery {
    markdownRemark(frontmatter: { templateKey: { eq: "general_settings" } }) {
      frontmatter {
        og_image {
          childImageSharp {
            original {
              width
              height
              src
            }
          }
        }
      }
    }
    site {
      siteMetadata {
        siteUrl
        description
      }
    }
  }
`;

export default function genericHelmet({ location }) {
  return (
    <StaticQuery
      query={HELMET_QUERY}
      render={data => (
        <Helmet>
          <meta
            name="description"
            content={data.site.siteMetadata.description}
          />
          <meta
            property="og:image"
            content={`${data.site.siteMetadata.siteUrl}${
              data.markdownRemark.frontmatter.og_image.childImageSharp.original
                .src
            }`}
          />
          <meta
            property="og:image:width"
            content={
              data.markdownRemark.frontmatter.og_image.childImageSharp.original
                .width
            }
          />
          <meta
            property="og:image:height"
            content={
              data.markdownRemark.frontmatter.og_image.childImageSharp.original
                .height
            }
          />
        </Helmet>
      )}
    />
  );
}
