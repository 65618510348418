import React from "react";
import Layout from "../components/Layout/Layout";
import GenericHelmet from "../components/Helmets/GenericHelmet";
import Quotes from "../components/Sections/Quotes";
import styles from "./anmeldelser.module.scss";
import { graphql } from "gatsby";

export default function Anmeldelser({ location, data }) {
  console.log(data);
  if (data !== undefined)
    return (
      <>
        <GenericHelmet location={location} />
        <Layout location={location}>
          <section id="anmeldelser" className={styles.section}>
            <div className={styles.container}>
              <div className={styles.reviewContainer}>
                <h2 id="customers">Tilbakemeldinger fra kunder</h2>
                <Quotes
                  reviews={data.allMarkdownRemark.nodes.filter(
                    quote => quote.frontmatter.templateKey === "customerReviews"
                  )}
                  noButton={true}
                />

                <h2 id="musicians" style={{ marginTop: "3rem" }}>
                  Tilbakemeldinger fra musikere
                </h2>
                <Quotes
                  reviews={data.allMarkdownRemark.nodes.filter(
                    quote => quote.frontmatter.templateKey === "musicianReviews"
                  )}
                  noButton={true}
                />
              </div>
            </div>
          </section>
        </Layout>
      </>
    );
}

export const query = graphql`
  query MusiciansQuotePageQuery {
    allMarkdownRemark(
      filter: {
        frontmatter: {
          templateKey: { in: ["musicianReviews", "customerReviews"] }
        }
      }
    ) {
      nodes {
        frontmatter {
          templateKey
          name
          quote
          visibleOnFrontpage
          cloudinary_image {
            image
          }
        }
      }
    }
  }
`;
