import React from "react";
import Img from "gatsby-image";
import styles from "./Quotes.module.scss";
import Button from "../Button";
import { Link } from "gatsby";
import Image from "../Image";

export default function Quotes({ reviews, noButton, restricted = false }) {
  const visibleReviews =
    restricted === true
      ? reviews.filter(review => review.frontmatter.visibleOnFrontpage === true)
      : reviews;

  return (
    <div className={styles.container}>
      <div className={styles.quotes}>
        {visibleReviews.length > 0 &&
          visibleReviews.map((review, index) => {
            return (
              <div key={index} className={styles.reviewArea}>
                <div className={styles.imageContainer}>
                  <Image
                    className={styles.cloudinary_image}
                    cloudinaryImage={review.frontmatter.cloudinary_image}
                  />
                </div>
                <blockquote className={styles.quote}>
                  {review.frontmatter.quote}
                  <div className={styles.quoteAuthor}>
                    {review.frontmatter.name}
                  </div>
                </blockquote>
              </div>
            );
          })}
        {reviews.length > 2 && noButton !== true ? (
          <div className={styles.readMoreContainer}>
            <Button
              component={Link}
              to="anmeldelser"
              className={styles.readMore}
              outline={true}
              width="auto"
            >
              Les flere tilbakemeldinger
            </Button>
          </div>
        ) : null}
      </div>
    </div>
  );
}
